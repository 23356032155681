












































































































































import { Component, Vue } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import dayjs from 'dayjs';
import { isUserAllowed } from '@/utils/rbac.util';
import { PermissionsMatrixActionsEnum } from '@/store/modules/roles-and-permissions/types/roles-and-permissions.types';
import {
  JAAS_APP,
  JAAS_APP_CARD_DETAILS
} from '@/jbi-shared/types/jaas-app.types';
import { UserSubscription } from '@/store/modules/subscription/subscription.state';
import { RootState } from '@/store/store';
import { UserGroup } from '@/store/modules/user-groups/user-groups.state';
import { Profile } from '@/store/modules/profile/profile.state';

@Component({
  name: 'Sidebar'
})
export default class Sidebar extends Vue {
  @Action('subscription/getMySubscriptions')
  getMySubscriptions!: () => void;

  @Action('userGroups/getUserGroups')
  getUserGroups!: () => void;

  @Action('profile/getMe')
  getMe!: () => void;

  @State((state: RootState) => state.subscription.subscriptions)
  subscriptions!: UserSubscription[];

  @State(
    (state: RootState) => state.subscription.apiState.getMySubscriptions.loading
  )
  loading!: boolean;

  @State((state: RootState) => state.userGroups.groups)
  groups!: UserGroup[];

  @State((state: RootState) => state.userGroups.apiState.getUserGroups.loading)
  groupsLoading!: boolean;

  @State((state: RootState) => state.profile.profile)
  userProfile!: Profile;

  get products() {
    if (!this.subscriptions) {
      return [];
    }
    // Define which products to show in the sidebar
    const productApps = [JAAS_APP.sumari, JAAS_APP.paces];
    const productItems = productApps.map((appId) => {
      // Find if user has a subscription for this product
      const subscription = this.subscriptions.find(
        (s) => s.applicationId === appId
      );
      const appDetails = JAAS_APP_CARD_DETAILS[appId];

      // Transform raw subscription data into a display-friendly format
      return {
        id: appId,
        logo: this.getApplicationLogo(appId),
        description: appDetails.applicationDescription || '',
        expiryDate: subscription?.endedAt || '',
        // hasSubscription is true only if:
        // 1. subscription exists (!!subscription)
        // 2. subscription end date is in the future (not expired)
        hasSubscription:
          !!subscription && new Date(subscription.endedAt) > new Date()
      };
    });

    // Sort products: subscribed products first, then unsubscribed
    return productItems.sort((a, b) => {
      if (a.hasSubscription && !b.hasSubscription) return -1;
      if (!a.hasSubscription && b.hasSubscription) return 1;
      // If both have same subscription status, maintain original order (Sumari first, then Paces)
      return productApps.indexOf(a.id) - productApps.indexOf(b.id);
    });
  }

  public getApplicationLogo(applicationId: JAAS_APP) {
    if (applicationId === JAAS_APP.paces) {
      return require(`@/jbi-shared/assets/images/logo_paces@2x.png`);
    }

    if (applicationId === JAAS_APP.sumari) {
      return require(`@/jbi-shared/assets/images/jbisumari_card_img.png`);
    }

    if (applicationId === JAAS_APP.training) {
      return require(`@/jbi-shared/assets/images/jbitraining_card_img.png`);
    }
    return '';
  }

  async mounted() {
    this.getUserGroups();
    this.getMySubscriptions();
    this.getMe();
  }

  getFormattedDate(date: string | undefined): string {
    return date ? dayjs(date).format('DD MMM YYYY') : 'Not subscribed';
  }

  handleManage() {
    this.$router.push('/products');
  }

  handleLaunch(product: any) {
    // Prevent launching if user doesn't have an active subscription
    if (!product.hasSubscription) {
      this.$router.push('/products');
      return;
    }
    // Dynamically launch the app based on the product id, following the logic in the Product page.
    window.location.href = `${process.env.VUE_APP_BACKEND_HOSTNAME}/${product.id}/launch-app`;
  }

  navigateToGroupManagement() {
    this.$router.push('/admin/group-management?tab=Groups');
  }

  // Use this when you want users without group admin access to not view groups and group management page
  // then use function to wrap the relevant code block for the groups section.
  get canViewAllGroups(): boolean {
    return isUserAllowed(
      PermissionsMatrixActionsEnum.READ,
      'group_administration-groups'
    );
  }

  get userName(): string {
    if (!this.userProfile) return '';
    return `${this.userProfile.firstName} ${this.userProfile.lastName}`;
  }

  get initials(): string {
    if (!this.userProfile) return '';
    const firstName = this.userProfile.firstName || '';
    return `${firstName.charAt(0)}`;
  }
}
