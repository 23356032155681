





























































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import dayjs from 'dayjs';
import { RegionKey, REGION_COUNTRIES } from '@/utils/regions.util';
import { Event } from '@/store/modules/events/events.state';
import { RootState } from '@/store/store';

@Component
export default class EventComponent extends Vue {
  $refs!: {
    scrollContainer: HTMLElement;
  };

  @Action('events/getEvents')
  getEvents!: () => void;

  @State((state: RootState) => state.events.events)
  events!: Event[];

  @State((state: RootState) => state.events.apiState.getEvents.loading)
  loading!: boolean;

  @State((state: RootState) => state.events.apiState.getEvents.error)
  error!: boolean;

  private selectedRegion: RegionKey = 'ALL REGIONS';
  private currentSlide = 0;
  private regions = Object.keys(REGION_COUNTRIES) as RegionKey[];
  private scrollAmount = 276 + 8; // card width + gap
  private isAtStart = true;
  private isAtEnd = false;
  private scrollHandler: EventListener | null = null;
  private isDragging = false;

  mounted() {
    this.getEvents();
    // Add scroll event listener
    this.$nextTick(() => {
      this.checkScrollPosition();
      if (this.$refs.scrollContainer) {
        // Use a debounced scroll handler for better performance
        const scrollHandler = this.debounce(this.checkScrollPosition, 50);
        this.$refs.scrollContainer.addEventListener(
          'scroll',
          scrollHandler as EventListener
        );
        // Also check on touch events for mobile
        this.$refs.scrollContainer.addEventListener(
          'touchmove',
          scrollHandler as EventListener
        );
        // Add mouse and touch end events to document for drag scrolling
        document.addEventListener('mouseup', this.checkScrollPosition);
        document.addEventListener('touchend', this.checkScrollPosition);
        // Add wheel event listener for touchpad scrolling
        this.$refs.scrollContainer.addEventListener('wheel', this.handleWheel);
        // Store the handler for cleanup
        this.scrollHandler = scrollHandler as EventListener;
      }
    });
  }

  beforeDestroy() {
    // Remove scroll event listener
    if (this.$refs.scrollContainer && this.scrollHandler) {
      this.$refs.scrollContainer.removeEventListener(
        'scroll',
        this.scrollHandler
      );
      this.$refs.scrollContainer.removeEventListener(
        'touchmove',
        this.scrollHandler
      );
      this.$refs.scrollContainer.removeEventListener('wheel', this.handleWheel);
    }
    // Remove document event listeners
    document.removeEventListener('mouseup', this.checkScrollPosition);
    document.removeEventListener('touchend', this.checkScrollPosition);
  }

  // Debounce function to limit how often the scroll position is checked
  debounce(fn: (...args: any[]) => void, delay: number) {
    let timeout: number | null = null;
    return (...args: any[]) => {
      if (timeout !== null) {
        clearTimeout(timeout);
      }
      timeout = window.setTimeout(() => {
        fn(...args);
        timeout = null;
      }, delay);
    };
  }

  @Watch('filteredEvents')
  onEventsChanged() {
    // When events or region changes, check scroll position after DOM updates
    this.$nextTick(() => {
      this.checkScrollPosition();
    });
  }

  scrollNext() {
    const container = this.$refs.scrollContainer;
    if (container) {
      container.scrollBy({ left: this.scrollAmount, behavior: 'smooth' });
      // Force update the scroll position after animation completes
      setTimeout(() => {
        this.checkScrollPosition();
      }, 500);
    }
  }

  scrollPrev() {
    const container = this.$refs.scrollContainer;
    if (container) {
      container.scrollBy({ left: -this.scrollAmount, behavior: 'smooth' });
      // Force update the scroll position after animation completes
      setTimeout(() => {
        this.checkScrollPosition();
      }, 500);
    }
  }

  get filteredEvents() {
    if (this.selectedRegion === 'ALL REGIONS') {
      return this.events;
    }
    return this.events.filter((event) =>
      REGION_COUNTRIES[this.selectedRegion].includes(event.field_country)
    );
  }

  get maxSlides() {
    return Math.max(0, Math.ceil(this.filteredEvents.length / 3) - 1);
  }

  getJBIImagePath(imagePath: string): string {
    return `https://jbi.global${imagePath}`;
  }

  formatMonth(date: string): string {
    return dayjs(date).format('MMM');
  }

  formatYear(date: string): string {
    return dayjs(date).format('YYYY');
  }

  formatDateRange(startDate: string, endDate: string): string {
    if (startDate === endDate) {
      return dayjs(startDate).format('D MMM YYYY');
    }
    return `${dayjs(startDate).format('D MMM YYYY')} - ${dayjs(endDate).format(
      'D MMM YYYY'
    )}`;
  }

  decodeEventLocation(location: string): string {
    // Simple function that only replaces &amp; with &
    return location ? location.replace(/&amp;/g, '&') : '';
  }

  // Track when user starts dragging/scrolling
  startDragScroll() {
    // Set a flag to check position after drag ends
    this.isDragging = true;
    // Force check after drag ends with a slight delay
    setTimeout(() => {
      this.checkScrollPosition();
    }, 100);
  }

  checkScrollPosition() {
    const container = this.$refs.scrollContainer;
    if (container) {
      // Consider any scroll beyond 5px as "not at start" for better UX with touchpad
      const wasAtStart = this.isAtStart;
      this.isAtStart = container.scrollLeft < 5;
      this.isAtEnd =
        container.scrollLeft + container.clientWidth >=
        container.scrollWidth - 10;

      // If state changed from at-start to not-at-start, force a re-render
      if (wasAtStart && !this.isAtStart) {
        this.$forceUpdate();
      }

      // For touchpad scrolling, we need to be more aggressive with updates
      if (!this.isAtStart) {
        // Force Vue to update the DOM
        this.$nextTick(() => {
          // This ensures the button visibility is updated
          const prevButton = this.$el.querySelector(
            '.nav-arrow.prev'
          ) as HTMLElement;
          if (prevButton) {
            prevButton.style.display = 'flex';
          }
        });
      }
    }
  }

  // Handle wheel events (touchpad scrolling)
  handleWheel(event: WheelEvent) {
    const container = this.$refs.scrollContainer;
    if (container && event.deltaX !== 0) {
      // If there's horizontal scrolling happening
      // Force check position immediately
      this.checkScrollPosition();

      // If we're scrolling right and we're at the start, make sure the back button appears
      if (event.deltaX > 0 && this.isAtStart) {
        // Manually set isAtStart to false to force the back button to appear
        this.isAtStart = false;

        // Force Vue to update the DOM
        this.$nextTick(() => {
          const prevButton = this.$el.querySelector(
            '.nav-arrow.prev'
          ) as HTMLElement;
          if (prevButton) {
            prevButton.style.display = 'flex';
          }
        });
      }

      // Check again after the scroll animation might have completed
      setTimeout(() => {
        this.checkScrollPosition();
      }, 100);
    }
  }
}
