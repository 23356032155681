export type RegionKey =
  | 'ALL REGIONS'
  | 'AFRICA'
  | 'AMERICAS'
  | 'ASIA'
  | 'AUSTRALASIA'
  | 'EUROPE';

export const REGION_COUNTRIES: Record<RegionKey, string[]> = {
  'ALL REGIONS': [],
  'AFRICA': [
    'South Africa',
    'Nigeria',
    'Kenya',
    'Ethiopia',
    'Egypt',
    'Morocco',
    'Ghana',
    'Tanzania',
    'Uganda',
    'Rwanda',
    'Zimbabwe',
    'Zambia',
    'Botswana',
    'Namibia',
    'Mozambique',
    'Madagascar',
    'Mauritius',
    'Seychelles',
    'Senegal',
    'Mali',
    'Ivory Coast',
    'Cameroon',
    'Congo',
    'Angola',
    'Tunisia',
    'Algeria',
    'Libya',
    'Sudan',
    'South Sudan',
    'Somalia'
  ],
  'AMERICAS': [
    'USA',
    'Canada',
    'Mexico',
    'Brazil',
    'Argentina',
    'Chile',
    'Colombia',
    'Peru',
    'Venezuela',
    'Ecuador',
    'Bolivia',
    'Paraguay',
    'Uruguay',
    'Guyana',
    'Suriname',
    'French Guiana',
    'Panama',
    'Costa Rica',
    'Nicaragua',
    'Honduras',
    'El Salvador',
    'Guatemala',
    'Belize',
    'Jamaica',
    'Cuba',
    'Haiti',
    'Dominican Republic',
    'Puerto Rico',
    'Bahamas',
    'Trinidad and Tobago'
  ],
  'ASIA': [
    'China',
    'Japan',
    'South Korea',
    'North Korea',
    'India',
    'Pakistan',
    'Bangladesh',
    'Sri Lanka',
    'Nepal',
    'Bhutan',
    'Myanmar',
    'Thailand',
    'Vietnam',
    'Cambodia',
    'Laos',
    'Malaysia',
    'Singapore',
    'Indonesia',
    'Philippines',
    'Taiwan',
    'Hong Kong',
    'Macau',
    'Mongolia',
    'Kazakhstan',
    'Uzbekistan',
    'Kyrgyzstan',
    'Tajikistan',
    'Turkmenistan',
    'Afghanistan',
    'Iran',
    'Iraq',
    'Saudi Arabia',
    'UAE',
    'Qatar',
    'Bahrain',
    'Kuwait',
    'Oman',
    'Yemen',
    'Jordan',
    'Lebanon',
    'Syria',
    'Israel',
    'Palestine',
    'Turkey',
    'Cyprus'
  ],
  'AUSTRALASIA': [
    'Australia',
    'New Zealand',
    'Papua New Guinea',
    'Fiji',
    'Solomon Islands',
    'Vanuatu',
    'New Caledonia',
    'Samoa',
    'Tonga',
    'American Samoa',
    'French Polynesia'
  ],
  'EUROPE': [
    'UK',
    'Wales',
    'Scotland',
    'Ireland',
    'France',
    'Germany',
    'Italy',
    'Spain',
    'Portugal',
    'Netherlands',
    'Belgium',
    'Luxembourg',
    'Switzerland',
    'Austria',
    'Sweden',
    'Norway',
    'Denmark',
    'Finland',
    'Iceland',
    'Greece',
    'Poland',
    'Romania',
    'Hungary',
    'Czech Republic',
    'Slovakia',
    'Slovenia',
    'Croatia',
    'Serbia',
    'Bulgaria',
    'Albania',
    'Montenegro',
    'Macedonia',
    'Estonia',
    'Latvia',
    'Lithuania',
    'Ukraine',
    'Belarus',
    'Moldova'
  ]
};
