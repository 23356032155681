































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Action, State } from 'vuex-class';
import Sidebar from './components/Sidebar.vue';
import Container from '@/components/Container.vue';
import { RootState } from '../../store/store';
import EventComponent from './components/EventComponent.vue';
import JBIContentCommon from './components/JBIContentCommon.vue';

@Component<LandingPage>({
  components: {
    Container,
    Sidebar,
    EventComponent,
    JBIContentCommon
  }
})
export default class LandingPage extends Vue {
  @Action('profile/getMe')
  public getMe!: () => void;

  @State((state: RootState) => state.profile.profile)
  public profile!: any;

  public created() {
    if (!this.profile) {
      this.getMe();
    }
  }
}
