











































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import { ContentItem } from '@/store/modules/content/content.state';
import { RootState } from '@/store/store';
import dayjs from 'dayjs';

@Component
export default class JBIContentCommon extends Vue {
  @Prop({ required: true }) readonly title!: string;
  @Prop({ required: true }) readonly viewMoreLink!: string;
  @Prop({ required: true }) readonly apiLink!: string;
  @Prop({ required: true }) readonly errorTitle!: string;

  @Action('content/getShortCourses') getShortCourses!: (
    apiLink: string
  ) => Promise<void>;
  @Action('content/getEbpResources') getEbpResources!: (
    apiLink: string
  ) => Promise<void>;
  @Action('content/getNews') getNews!: (apiLink: string) => Promise<void>;
  @Action('content/getNewsBuzz') getNewsBuzz!: (
    apiLink: string
  ) => Promise<void>;

  @State((state: RootState) => state.content.shortCourses)
  shortCourses!: ContentItem[];
  @State((state: RootState) => state.content.ebpResources)
  ebpResources!: ContentItem[];
  @State((state: RootState) => state.content.news) news!: ContentItem[];
  @State((state: RootState) => state.content.newsBuzz) newsBuzz!: ContentItem[];

  @State((state: RootState) => state.content.apiState.shortCourses.loading)
  shortCoursesLoading!: boolean;
  @State((state: RootState) => state.content.apiState.ebpResources.loading)
  ebpResourcesLoading!: boolean;
  @State((state: RootState) => state.content.apiState.news.loading)
  newsLoading!: boolean;
  @State((state: RootState) => state.content.apiState.newsBuzz.loading)
  newsBuzzLoading!: boolean;

  @State((state: RootState) => state.content.apiState.shortCourses.error)
  shortCoursesError!: boolean;
  @State((state: RootState) => state.content.apiState.ebpResources.error)
  ebpResourcesError!: boolean;
  @State((state: RootState) => state.content.apiState.news.error)
  newsError!: boolean;
  @State((state: RootState) => state.content.apiState.newsBuzz.error)
  newsBuzzError!: boolean;

  // Fix field order
  $refs!: {
    scrollContainer: HTMLElement;
  };

  private currentSlide = 0;
  private scrollAmount = 276 + 8; // card width + gap
  private isAtStart = true;
  private isAtEnd = false;
  private scrollHandler: EventListener | null = null;
  private isDragging = false;

  // Fix Function type and if statement bracing
  debounce(fn: (...args: any[]) => void, delay: number) {
    let timeout: number | null = null;
    return (...args: any[]) => {
      if (timeout !== null) {
        clearTimeout(timeout);
      }
      timeout = window.setTimeout(() => {
        fn.apply(this, args);
        timeout = null;
      }, delay);
    };
  }

  getImageUrl(item: ContentItem): string {
    if (!item) {
      return '';
    }

    // Handle JBI Buzz images
    if (this.isNewsBuzz && item.image) {
      return item.image;
    }

    // Handle Short Courses and other content types using field_teaser_image
    if (item.field_teaser_image) {
      return item.field_teaser_image.startsWith('http')
        ? item.field_teaser_image
        : `${this.baseUrl}${item.field_teaser_image}`;
    }

    // Fallback to teaserImageUrl if field_teaser_image is not available
    if (item.teaserImageUrl) {
      return item.teaserImageUrl.startsWith('http')
        ? item.teaserImageUrl
        : `${this.baseUrl}${item.teaserImageUrl}`;
    }

    return '';
  }

  formatDate(date?: string): string {
    if (!date) {
      return '';
    }
    if (/^[A-Za-z]{3}\s\d{4}$/.test(date)) {
      return date;
    }
    return dayjs(date).format('MMM YYYY');
  }

  truncateText(text: string, maxLength: number = 64): string {
    if (!text) {
      return '';
    }
    return text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
  }

  // Carousel methods
  scrollNext() {
    const container = this.$refs.scrollContainer;
    if (container) {
      container.scrollBy({ left: this.scrollAmount, behavior: 'smooth' });
      setTimeout(() => {
        this.checkScrollPosition();
      }, 500);
    }
  }

  scrollPrev() {
    const container = this.$refs.scrollContainer;
    if (container) {
      container.scrollBy({ left: -this.scrollAmount, behavior: 'smooth' });
      setTimeout(() => {
        this.checkScrollPosition();
      }, 500);
    }
  }

  startDragScroll() {
    this.isDragging = true;
    setTimeout(() => {
      this.checkScrollPosition();
    }, 100);
  }

  handleWheel(event: WheelEvent) {
    const container = this.$refs.scrollContainer;
    if (container && event.deltaX !== 0) {
      this.checkScrollPosition();

      if (event.deltaX > 0 && this.isAtStart) {
        this.isAtStart = false;
        this.$nextTick(() => {
          const prevButton = this.$el.querySelector(
            '.nav-arrow.prev'
          ) as HTMLElement;
          if (prevButton) {
            prevButton.style.display = 'flex';
          }
        });
      }

      setTimeout(() => {
        this.checkScrollPosition();
      }, 100);
    }
  }

  checkScrollPosition() {
    const container = this.$refs.scrollContainer;
    if (container) {
      const wasAtStart = this.isAtStart;
      this.isAtStart = container.scrollLeft < 5;
      this.isAtEnd =
        container.scrollLeft + container.clientWidth >=
        container.scrollWidth - 10;

      if (wasAtStart && !this.isAtStart) {
        this.$forceUpdate();
      }

      if (!this.isAtStart) {
        this.$nextTick(() => {
          const prevButton = this.$el.querySelector(
            '.nav-arrow.prev'
          ) as HTMLElement;
          if (prevButton) {
            prevButton.style.display = 'flex';
          }
        });
      }
    }
  }

  get baseUrl(): string {
    return process.env.VUE_APP_JBI_GLOBAL_LINK_URL || 'https://jbi.global';
  }

  get isShortCourses(): boolean {
    return this.title === 'SHORT COURSES';
  }

  get isEbpResources(): boolean {
    return this.title === 'EBP RESOURCES';
  }

  get isNews(): boolean {
    return this.title === 'NEWS IN YOUR REGION';
  }

  get isNewsBuzz(): boolean {
    return this.title === 'OUR NEWSLETTER' || this.title === 'JBI BUZZ';
  }

  get loading(): boolean {
    if (this.isShortCourses) {
      return this.shortCoursesLoading;
    }
    if (this.isEbpResources) {
      return this.ebpResourcesLoading;
    }
    if (this.isNews) {
      return this.newsLoading;
    }
    if (this.isNewsBuzz) {
      return this.newsBuzzLoading;
    }
    return false;
  }

  get error(): boolean {
    if (this.isShortCourses) {
      return this.shortCoursesError;
    }
    if (this.isEbpResources) {
      return this.ebpResourcesError;
    }
    if (this.isNews) {
      return this.newsError;
    }
    if (this.isNewsBuzz) {
      return this.newsBuzzError;
    }
    return false;
  }

  get items(): ContentItem[] {
    if (this.isShortCourses) {
      return this.shortCourses.slice(0, 6).map((item) => ({
        ...item,
        articleLink: this.formatLink(item.link || item.field_link)
      }));
    }
    if (this.isEbpResources) {
      return this.ebpResources.slice(0, 3).map((item) => ({
        ...item,
        articleLink: this.formatLink(item.link || item.field_link)
      }));
    }
    if (this.isNews) {
      return this.news.slice(0, 3);
    }
    if (this.isNewsBuzz) {
      return this.newsBuzz.map((item) => ({
        id: item.id,
        title: item.issue || 'JBI Buzz',
        articleLink: item.link,
        teaserImageUrl: item.image,
        date: item.date
      }));
    }
    return [];
  }

  async mounted() {
    await this.fetchData();
    if (this.isNewsBuzz) {
      this.$nextTick(() => {
        this.checkScrollPosition();
        if (this.$refs.scrollContainer) {
          const scrollHandler = this.debounce(this.checkScrollPosition, 50);
          this.$refs.scrollContainer.addEventListener('scroll', scrollHandler);
          this.$refs.scrollContainer.addEventListener(
            'touchmove',
            scrollHandler
          );
          document.addEventListener('mouseup', this.checkScrollPosition);
          document.addEventListener('touchend', this.checkScrollPosition);
          this.$refs.scrollContainer.addEventListener(
            'wheel',
            this.handleWheel
          );
        }
      });
    }
  }

  beforeDestroy() {
    if (this.isNewsBuzz && this.$refs.scrollContainer) {
      this.$refs.scrollContainer.removeEventListener(
        'scroll',
        this.checkScrollPosition
      );
      this.$refs.scrollContainer.removeEventListener(
        'touchmove',
        this.checkScrollPosition
      );
      this.$refs.scrollContainer.removeEventListener('wheel', this.handleWheel);
      document.removeEventListener('mouseup', this.checkScrollPosition);
      document.removeEventListener('touchend', this.checkScrollPosition);
    }
  }

  async fetchData() {
    if (this.isShortCourses) {
      this.getShortCourses(this.apiLink);
    } else if (this.isEbpResources) {
      this.getEbpResources(this.apiLink);
    } else if (this.isNews) {
      this.getNews(this.apiLink);
    } else if (this.isNewsBuzz) {
      this.getNewsBuzz(this.apiLink);
    }
  }

  formatLink(link?: string): string {
    if (!link) {
      return '';
    }

    // If it's already a full URL, return it
    if (link.startsWith('http://') || link.startsWith('https://')) {
      return link;
    }

    // If it's an HTML link, extract the href
    const match = link.match(/<a href="([^"]+)"[^>]*>([^<]+)<\/a>/);
    if (match) {
      const path = match[1];
      if (path.startsWith('http://') || path.startsWith('https://')) {
        return path;
      }
      const cleanedPath = path.startsWith('/') ? path.slice(1) : path;
      return `${this.baseUrl}/${cleanedPath}`;
    }

    // If it's a relative path, join it with base URL
    const cleanPath = link.startsWith('/') ? link.slice(1) : link;
    return `${this.baseUrl}/${cleanPath}`;
  }

  stripHtmlTags(text?: string): string {
    if (!text) {
      return '';
    }
    return text.replace(/<[^>]*>/g, '');
  }

  formatMonth(date?: string): string {
    if (!date) {
      return '';
    }

    try {
      // Convert date string to Safari-compatible format
      let dateStr = date;

      // Handle ISO format (YYYY-MM-DD)
      if (dateStr.includes('-')) {
        const parts = dateStr.split('-');
        if (parts.length >= 2) {
          const year = parts[0];
          const month = parts[1];
          // Create a date using numbers which Safari handles well
          dateStr = `${year}/${month}/01`;
        }
      }

      const d = new Date(dateStr);
      if (!isNaN(d.getTime())) {
        // Use dayjs to format only after we have a valid date object
        return dayjs(d).format('MMM');
      }

      return '';
    } catch (error) {
      return '';
    }
  }

  formatYear(date?: string): string {
    if (!date) {
      return '';
    }

    try {
      // Convert date string to Safari-compatible format
      let dateStr = date;

      // Handle ISO format (YYYY-MM-DD)
      if (dateStr.includes('-')) {
        const parts = dateStr.split('-');
        if (parts.length >= 2) {
          const year = parts[0];
          const month = parts[1];
          // Create a date using numbers which Safari handles well
          dateStr = `${year}/${month}/01`;
        }
      }

      const d = new Date(dateStr);
      if (!isNaN(d.getTime())) {
        // Use dayjs to format only after we have a valid date object
        return dayjs(d).format('YYYY');
      }

      return '';
    } catch (error) {
      return '';
    }
  }
}
